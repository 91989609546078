<template>
    <div class="test">
      <el-header class="header">
        <el-row class="edit-header">
          <el-col :span="2" class="button"><el-button type="warning" icon="el-icon-receiving" @click="save">Save</el-button></el-col>
        </el-row>
      </el-header>
  
      <el-container class="container">
  
        <el-main class="main">
          <div class="ques">
            <div class="title">
              Step 2 Define Linear Regression Model Coefficients
            </div>
          </div>
          <el-form ref="form" label-width="100px">
            <el-row>
              <el-col :span="1">
                <p> </p>
              </el-col>
              <el-col :span="4">
                <p>LR Name</p>
              </el-col>
              <el-col :span="1">
                <p> </p>
              </el-col>
              <el-col :span="4">
                <p>Coefficient</p>
              </el-col>
              <el-col :span="2">
                <p> </p>
              </el-col>
              <el-col :span="4">
                <p>Value</p>
              </el-col>
              <el-col :span="1">
                <p> </p>
              </el-col>
              <el-col :span="4">
                <p>ML Name</p>
              </el-col>
            </el-row>
            <el-form-item v-for="(item,index) in values" :key="index">
              <el-row>
                  <el-col :span="4">
                  <el-input :disabled="true" v-model="item.LR_variable_name"></el-input>
                  </el-col>
                  <el-col :span="1">
                  <p style="line-height: 13px;margin-left:10px"> *</p>
                  </el-col>
                  <el-col :span="4">
                  <el-input v-model="item.coefficient" placeholder="Coefficient" ></el-input>
                  </el-col>
                  <el-col :span="2">
                    <p> </p>
                  </el-col>
                  <el-col :span="4">
                  <el-input type="textarea" :rows="JSON.stringify(item.conditions).length/20" :disabled="true" v-model="description[index]"></el-input>
                  </el-col>
                  <el-col :span="2">
                    <p> </p>
                  </el-col>
                  <el-col :span="4">
                  <el-input :disabled="true" v-model="item.ML_variable_name"></el-input>
                  </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </el-main>
      </el-container>
    </div>
</template>
  
<script>
import getDataApi from "@/utils/getDataApi";
import saveDataApi from "@/utils/saveDataApi";


export default {
  name: "test",
  mixins: [getDataApi, saveDataApi],
  data() {
    return {
      values:[],
      description: [],
      qid: this.$route.query.qid,
    }
  },
  components: {
  },
  methods: {
    save() {
      for(let i = 0; i < this.values.length; i++) {
        let item = this.values[i]
        if(item.coefficient == ''){
          this.$message.error("Empty coefficient!");
          return;
        }
      }
      if(this.qid != undefined){
        window.localStorage.setItem(`linear_model${this.qid}`, JSON.stringify(this.values));
      }
      else {
        window.localStorage.setItem('linear_model', JSON.stringify(this.values));
      }
      this.$message.success("Step 2 Saved!");
    },
  },
  computed: {
  },
  created() {
    if(this.qid != undefined){
      if(window.localStorage.getItem(`linear_model${this.qid}`) != undefined){
        this.values = JSON.parse(window.localStorage.getItem(`linear_model${this.qid}`))
        for(let i = 0; i < this.values.length; i++) {
          var msg = ""
          for(let j = 0; j < this.values[i].conditions.length; j++) {
            msg += `If ${this.values[i].conditions[j].condition}, value = ${this.values[i].conditions[j].value}`
            if(j < this.values[i].conditions.length-1) msg += "\n"
          }
          this.description.push(msg)
        }
      }
      else {
        this.$axios({
          method: 'get',
          url: 'https://api.oxvalue.cn:7999/api/question/v0.1/questionnaire_admin/'+this.qid+'/',
          headers:{
              'Authorization': window.localStorage.getItem('ova_admin_token')
          }
        })
        .then(res => {
          var model_id = res.data.data.fk_plugin_id
          this.$axios({
            method: 'get',
            url: `https://api.oxvalue.cn:7999/api/plugin/v0.1/plugin/${model_id}/`,
            headers:{
                'Authorization': window.localStorage.getItem('ova_admin_token')
            }
          })
          .then(res2 => {
            this.values = JSON.parse(res2.data.data.linear_model);
            for(let i = 0; i < this.values.length; i++) {
              let msg = ""
              for(let j = 0; j < this.values[i].conditions.length; j++) {
                msg += `If ${this.values[i].conditions[j].condition}, value = ${this.values[i].conditions[j].value}`
                if(j < this.values[i].conditions.length-1) msg += "\n"
              }
              this.description.push(msg)
            }
          })
        })
      }
    }
    else {
      if(window.localStorage.getItem('linear_model') != undefined){
        this.values = JSON.parse(window.localStorage.getItem('linear_model'))
        for(let i = 0; i < this.values.length; i++) {
          let msg = ""
          for(let j = 0; j < this.values[i].conditions.length; j++) {
            msg += `If ${this.values[i].conditions[j].condition}, value = ${this.values[i].conditions[j].value}`
            if(j < this.values[i].conditions.length-1) msg += "\n"
          }
          this.description.push(msg)
        }
      }
    }
  },
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
}
</script>

<style>
.test .linkDialog{
  text-align: left;
}
.test .container {
  padding: 0;
  height: auto;
  min-height: 610px;
}

.test header {
  padding: 0 100px;
}

.test .el-container {
  padding: 0 100px;
}

.test .side {
  border-top: solid 1px #e3e3e3;
  border-right: solid 1px #e3e3e3;
  background: #FFFFFF;
}

.test .main {
  border-top: solid 1px #e3e3e3;
  background: #FFFFFF;
}

.test .edit {
  margin-top: 0;
  overflow: auto;
  height: 550px;
}

.test .outline {
  overflow: auto;
  height: 550px;
}

.test .ques-type {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .edit-title {
  color: black;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: solid #e3e3e3 2px;
}

.test .edit-ques {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .type-icon {
  color: #1687fd;
  display: inline-block;
}

.test .type-icon:hover {
  color: #409EFF;
  cursor: pointer;
}

.test .el-tabs__nav-scroll {
  text-align: center;
  height: 60px;
  margin: 0 60px;
}

.test .el-tabs__item {
  font-weight: bold;
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  color: black;
  position: relative;
}

.test .el-tabs__header {
  margin: 0;
}

.test .el-tree-node__content {
  padding-left: 10px !important;
  height: 40px;
}

/* .test .main {
  max-height: 610px;
} */

.test .ques .title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 26px;
}

.test .ques .description {
  text-align: left;
  font-size: 16px;
  padding-bottom: 30px;
  color: black;
  line-height: 30px;
}

.test .ques-block {
  padding-bottom: 15px;
  border-top: solid #e3e3e3 1px;
}

.test .ques-block:hover {
  background: #f5f5f5;
  transition: .3s;
}

.test .ques-block .must {
  font-weight: normal;
  color: crimson;
}

.test .block-title {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 20px 10px 10px;
  font-weight: bold;
}

.test .block-description {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #969696;
}

.test .block-refer {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #51c215;
}

.test .block-point {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #e59824;
}

.test .block-choice {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 5px 10px 10px;
}

.test .el-button-group>.el-button:first-child {
  border-radius: 0 0 0 8px;
}

.test .el-button-group>.el-button:last-child {
  border-radius: 0 0 8px 0;
}

.test .block-button .el-button {
  background: #b9b9b9;
  border: transparent;
  padding: 12px 16px;
  font-size: 16px;
}

.test .block-button .el-button:hover {
  background: #989898;
  border: transparent;
}

.test .bt {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.test .block-choice .el-textarea__inner {
  max-height: 100px;
}

.test .dialog{
  text-align: left;
  font-size: large;
}
.test .addOptionButton{
  display: inline-block;
  margin-left: 100px;
}
.test .deleteOptionButton{
  margin-left: 20px;
}

.test .logic-description {
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 6px;
  font-size: 14px;
  color: #aaaaaa;
}

.test .important {
  color: crimson;
}

.test .logic-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 12px;
  padding-bottom: 30px;
}

.test .logic-bt {
  text-align: center;
}

.test .addOptionButton{
  display: inline-block;
  margin-left: 100px;
  margin-bottom: 22px;
}

.test .deleteOptionButton{
  margin-left: 20px;
}

.test .settingDialog {
  text-align: center;
}

.test .settingDialog .el-dialog__body {
  padding-left: 30px;
}

.test .logic-form .el-input--suffix .el-input__inner {
  width: 620px;
}

.test .logic-info {
  color: #aaaaaa;
  padding-top: 2px;
  padding-left: 12px;
  padding-bottom: 25px;
}

.test .block-relation {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #1687fd;
}
</style>